<template>
    <div class="vipprivilge">
        <div class="vipprivilge-center-text">
            <el-row  style="margin: 0 auto;  background-color: #d1e1f9;width: 1350px;padding: 20px">
                <el-col :span="6">
                    <contentLeft  type="3"></contentLeft><!-- 资料显示左边部分，公共部分 -->
                </el-col>
                <el-col :span="18" style="background: #fff;">
                    <div class="con-base">
                        <el-col><b><span>|</span>基本信息</b></el-col>
                    </div>
                    <div style="padding: 50px;position: relative;" >
                        <img style="width: 100%;"  src="../assets/img/vip.png" alt="">
                        <div style="position: absolute;left: 70px;top:70px;color: #ffffff;">
                            <!--<p><span style="font-size: 20px;margin-left: 10px;">{{storage.vip_type==0?'暂无购买':'你是尊贵的vip'}}</span> <span style="color: #ffe000;margin-left: 40px;cursor: pointer;" @click="huiyuan()" >查看会员充值记录》</span>  </p>-->
                            <p><span style="font-size: 20px;margin-left: 10px;">{{storage.vip_type==1?'你是尊贵的vip':'暂无购买'}}</span>  </p>
                            <p style="margin-top: 20px;margin-bottom: 20px;">时间：{{storage.vip_createtime!=0?storage.vip_createtime+'---'+storage.vip_expitytime:'暂无购买'}}</p>
                            <p>剩余时间：{{parseInt(storage.day)}}天 </p>
                        </div>
                    </div>
                    <div class="pervipimg" style="padding-bottom: 50px;">
                        <div class="per-con-text" style="background: #fff;">
                            <div class="per-bottom-img">
                                <img style="width: 100%"  src="../assets/img/qiyevip.png" alt="">
                                <!--<img style="width: 100%"  src="../assets/img/qiyevip.png" @click="gerenvip(2)" alt="">-->
                            </div>
                            <div class="per-bottom-img" style="margin-top: 50px;"><img style="width: 100%" src="../assets/img/vip/personbtt.png" alt=""></div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
    import contentLeft from '@/components/contentLeft.vue';
    import {
        requestAdd_member,
        requestUpgrade_need_goldcoin,
        requestUpgrade_member,
        requestStorage
    } from "../api/request";
export default {
  name: "VipPrivilge",
  components: {
    contentLeft,
  },
    data(){
      return{
          storage:{},
      }
    },
    methods:{
        //查看会员记录
        huiyuan(){
            this.$router.push({ path: "/MyWallet" });
        },
        //当前vip时长
        storage_this(){
            this.storage.day=(this.storage.vip_expitytime-this.storage.vip_createtime)/86400
            this.storage.vip_createtime=this.storage.vip_createtime=='0'?0:this.common.retun_formatDate11(this.storage.vip_createtime)
            this.storage.vip_expitytime=this.storage.vip_expitytime=='0'?0:this.common.retun_formatDate11(this.storage.vip_expitytime)
        },
        //提示金币框
        process(title,type){
            let _this=this;
            _this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(type==4){
                    requestUpgrade_member().then(res=>{
                        if(res.code==0){
                            _this.$message({
                                type: 'success',
                                message: '升级成功!'
                            });
                            requestStorage().then(res => {
                                localStorage.setItem("storage",JSON.stringify(res.ret));
                                let storage=_this.Account.GetStorage();
                                _this.storage=storage;
                                _this.storage_this();
                            })
                        }else if(res.code==5008){
                            _this.$router.push({ path: "/MyPay" });
                        }
                    })
                }else {
                    let member_type={
                        member_type:type
                    }
                    requestAdd_member(member_type).then(res=>{
                        console.log(res.ret);
                        if(res.code==0){
                            _this.$message({
                                type: 'success',
                                message: '购买成功!'
                            });
                            requestStorage().then(res => {
                                localStorage.setItem("storage",JSON.stringify(res.ret));
                                let storage=_this.Account.GetStorage();
                                _this.storage=storage;
                                _this.storage_this();

                            })
                        }else if(res.code==5008){
                            _this.$router.push({ path: "/MyPay" });
                        }
                    })
                }
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消购买'
                });
            });
        },
        //购买会员
        gerenvip(type) {
            let type_tishi="",_this=this;
            if(type==4){
                requestUpgrade_need_goldcoin().then(res=>{
                    type_tishi="企业会员所需金币"+res.ret.enterprise_cost+",vip会员剩余时间折算换成金币"+res.ret.actual_goldcoin+",所需要支付的费用"+res.ret.pay_goldcoin+",所需要退回去的金币"+res.ret.subsidy_glodcoin;
                    _this.process(type_tishi,type)
                })
            }else {
                type_tishi="当前共有"+this.storage.goldcoin+"金币，确认是否立即购买？";
                _this.process(type_tishi,type)
            }

        },

    },
    mounted(){
        if(this.Account.GetSession()==null){
            this.$router.push({ path: "/" });
            return
        }
        let storage=this.Account.GetStorage();
        this.storage=storage;
        this.storage_this();

    }
};
</script>
<style scoped>
@import "../style/common.css";
.per-bottom-img{
}
.after{
    background: #ccae53 !important;
}
.per-con-text{
    margin: 0 50px;
  background-color: #cfdeff;
}
.per-con-text-top{
  width: 900px;
  padding: 30px 0 20px 0;
  margin-left: 45px;
  border-bottom: 1px solid #adc4f0 ;
  color: #0079fe;
  font-size: 18px;
}
.vipprivilge-center-text{
 background: #d1e1f9;
 background-color: #cfdeff;
 display: flex;
}
.per-left,.per-right{
float: left;
background-color: #cfdeff;
height: 500px;
width: 500px;
}
.vipprivilge-center-text ul{
  margin-top: 50px;
  margin-left: 45px;
}
.vipprivilge-center-text ul li{
 padding: 0 0 24px 0;
}
.vipprivilge-center-text ul li img{
  width: 15px;
  height: 15px;
  margin-right: 8px;
  overflow: hidden;
}
.per-right img{
  height: 300px;
  overflow: hidden;
}
.per-pay-an{
  padding: 20px 0 0 45px ;
  background-color: #cfdeff;
  overflow: hidden;
  height: 40px;
  width: 955px;
  margin-left: 50px;
}
.per-pay-an span{
  color: #0079fe;
  font-size: 20px;
}
.per-pay-an b{
  color: #9eaac4;
}
.per-pay-an button{
  width: 135px;
  height: 40px;
  margin-left: 10px;
  background-color: #386bd8;
  border: 1px solid #386bd8;
  border-radius: 2px;
  color: #fff;
}

.con-base{
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #efeff0;;

}
.con-base span{
    color:#007aff ;
}
.con-base b{
    margin-left: 50px;
    font-size: 16px;
}
.con-base b span,.con-detail b span{
    color: #007aff;
    margin-right: 7px;
}
.vip-category{
    height: 80px;
    line-height: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
}

</style>
